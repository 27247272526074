<template>
    <div>
        <Table :columns="columns" :data="resources" size="small" stripe>
            <template slot-scope="{ row }" slot="from_branch">
                {{ $root.$i18n.locale === "kh" ? row.from_branch_name_kh : row.from_branch_name_en }}
            </template>
            <template slot-scope="{ row }" slot="to_branch">
                {{ $root.$i18n.locale === "kh" ? row.to_branch_name_kh : row.to_branch_name_en }}
            </template>
        </Table>
    </div>
</template>
<script>
export default {
    props: {
        transfers: Array
    },
    computed: {
        resources() {
            return this.transfers;
        },
        columns() {
            return [
                {
                    title: this.$t("customer.fromBranch"),
                    slot: "from_branch",
                    width: 150
                },
                {
                    title: this.$t("customer.toBranch"),
                    slot: "to_branch",
                    width: 150
                },
                {
                    title: this.$t("customer.transferDate"),
                    key: "transfer_date",
                    align: "center",
                    width: 160
                },
                {
                    title: this.$t("customer.transferReason"),
                    key: "transfer_reason",
                    width: 400
                },
                {
                    title: this.$t("customer.transferred_by"),
                    key: "transferred_by",
                    width: 180
                }
            ];
        }
    }
};
</script>
